import React, { useEffect, useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography
} from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'next-i18next';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { object, string } from 'yup';

import { CONFIG } from '@/lib/config/config';
import { AssistantSignupComponent } from '../assistant/assistant-signup/assistant-signup.component';
import { ContentStyle, FormStyle, RootStyle, TextLink } from '../layout/layout.style';
import GridLoadingComponent from '../ui/grid/grid-loading.component';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';
import { useLoginUserMutation } from './queries/mutations/use-login-user.mutation';

const LoginComponent = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation('auth');
  const [showPassword, setShowPassword] = useState(false);
  const { pathname, query, asPath } = useRouter();
  const { data: user } = useUserQ();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const loginUserMutation = useLoginUserMutation();

  const router = useRouter();

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    // debugger;
    if (user) {
      router.push({ pathname, query }, asPath, {
        locale: user.language === null ? undefined : user.language
      });
    }
  }, [router, user, pathname, query, asPath]);

  const usernameVMR = t('login.validation.username.required', { ns: 'auth' });
  const passwordVMR = t('sign-up.validation.password.required', { ns: 'auth' });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: object({
      username: string().max(255).required(usernameVMR),
      password: string().max(255).required(passwordVMR)
    }),

    onSubmit: (values, { setSubmitting }) => {
      loginUserMutation.mutate(values);
      setSubmitting(false);
    }
  });
  // const isFormBusy = isChangingRoute || formik.isSubmitting || fetching;
  // const isFormBusy = false;
  const isFormBusy = formik.isSubmitting;

  if (user) {
    // @ts-ignore

    // eslint-disable-next-line no-underscore-dangle
    // session={page?.props?._session}
    <GridLoadingComponent />;
  }

  return (
    <RootStyle>
      <ContentStyle>
        <AssistantSignupComponent step='login' />
        <FormStyle>
          <Box component='form' onSubmit={formik.handleSubmit}>
            <Typography
              data-cy='login.headline'
              color='primary'
              variant='h3'
              display='flex'
              justifyContent='center'
            >
              {t('login.headline', { ns: 'auth' })}
            </Typography>

            <Typography variant='body2' display='flex' justifyContent='center'>
              <NextLink href={pathname} locale={language === 'en' ? 'de' : 'en'} legacyBehavior>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link
                  sx={{
                    color: 'text.secondary',
                    cursor: 'pointer',
                    textDecorationLine: 'none',
                    '&:hover': {
                      color: 'text.secondary',
                      textDecorationLine: 'underline'
                    }
                  }}
                >
                  {t('login.changeLanguage', { ns: 'auth' })}
                </Link>
              </NextLink>
            </Typography>

            <TextField
              error={Boolean(formik.touched.username && formik.errors.username)}
              fullWidth
              helperText={formik.touched.username && formik.errors.username}
              label={t('login.username', { ns: 'auth' })}
              margin='normal'
              name='username'
              data-cy='username'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type='username'
              value={formik.values.username}
              variant='outlined'
              // disabled={formik.isSubmitting}
              disabled={isFormBusy}
            />
            <TextField
              error={Boolean(formik.touched.password && formik.errors.password)}
              fullWidth
              helperText={formik.touched.password && formik.errors.password}
              label={t('sign-up.password', { ns: 'auth' })}
              data-cy='password'
              margin='normal'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      data-cy='toggle-password-visibility'
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              name='password'
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              type={showPassword ? 'text' : 'password'}
              value={formik.values.password}
              variant='outlined'
              // disabled={formik.isSubmitting}
              disabled={isFormBusy}
            />
            <Button
              type='submit'
              fullWidth
              data-cy='login.submit'
              // disabled={formik.isSubmitting}
              // disabled={fetching}
              disabled={isFormBusy}
              variant='contained'
              sx={{ mt: 3, mb: 4 }}
            >
              {t('login.submit', { ns: 'auth' })}
            </Button>
          </Box>
        </FormStyle>

        <TextLink
          dataCy='login.not-signed-up'
          href={CONFIG.URL.SIGN_UP.path}
          title={t('login.not-signed-up', { ns: 'auth' })}
        />
        <TextLink
          dataCy='login.resend-password'
          href={CONFIG.URL.USER_RESEND_PASSWORD.path}
          title={t('login.resend-password', { ns: 'auth' })}
        />
        <TextLink
          dataCy='login.no-activation-mail'
          href={CONFIG.URL.USER_RESEND_ACTIVATION.path}
          title={t('login.no-activation-mail', { ns: 'auth' })}
        />
      </ContentStyle>
    </RootStyle>
  );
};

export default LoginComponent;
