type WindowWithDataLayer = Window & {
  dataLayer: Record<string, unknown>[];
  user_id: string;
  isPaid: boolean;
  email: string;
  isTrial: boolean;
};

declare const window: WindowWithDataLayer;

export const pushAnalyticsEvent = (eventObject) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(eventObject);
};

export const pushUserDataToWindow = (
  user_id: string,
  isPaid: boolean,
  email: string,
  isTrial: number | null
) => {
  window.user_id = user_id;
  window.isPaid = isPaid;
  window.email = email;
  window.isTrial = isTrial ? true : false;
};

export const isEventAleadySet = (eventName: string) => {
  window.dataLayer = window.dataLayer || [];
  return window.dataLayer.some((data) => data.event === eventName);
};
