import { ReactElement } from 'react';

import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';

import { CONFIG } from '@/lib/config/config';
import withSession from '@/lib/helpers/authorization/with-session';

import LayoutComponent from '@/components/layout/layout.component';
import LoginWithTokenComponent from '@/components/login/login-with-token.component';
import LoginComponent from '@/components/login/user-login.component';
import { NextPageWithLayout } from '@/pages/_app';

const LoginPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { params } = router.query;
  const loginWithToken = !!(params && params[0]);

  return loginWithToken ? <LoginWithTokenComponent /> : <LoginComponent />;
};

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <LayoutComponent variant='base'>{page}</LayoutComponent>;
};

export const getServerSideProps = withSession(async ({ session: { isLoggedIn }, locale }) => {
  if (isLoggedIn) {
    return {
      redirect: {
        destination: CONFIG.SETTINGS.POST_LOGIN_URI,
        permanent: false
      }
    };
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'assistant',
        'common',
        'footer',
        'auth',
        'api-errors'
      ]))
    }
  };
});

export default LoginPage;
