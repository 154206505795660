import { useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { CONFIG } from '@/lib/config/config';

import GridLoadingComponent from '../ui/grid/grid-loading.component';
import { useLoginUserMutation } from './queries/mutations/use-login-user.mutation';
import { useUserQ } from '@/lib/hooks/queries/useUser.query';

// This component is used when the user clicks on a reset password link in an email or when he is authenticated via SSO (Google or Facebook - To be implemented)
const LoginWithTokenComponent = () => {
  const router = useRouter();
  const { query } = useRouter();

  const { token, redirect } = CONFIG.URL.LOGIN_SSO.deserializeParams(query, CONFIG.URL);
  const loginUserMutation = useLoginUserMutation();
  const { data: user } = useUserQ();
  const [isAlreadyRedirected, setIsAlreadyRedirected] = useState(false);

  useEffect(() => {
    if (token && !loginUserMutation.isSuccess) {
      loginUserMutation.mutate({
        password: token
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (loginUserMutation.isSuccess && !isAlreadyRedirected) {
      router.push(redirect || CONFIG.SETTINGS.POST_LOGIN_URI);
      setIsAlreadyRedirected(true);
    }
  }, [redirect, loginUserMutation, router, user, isAlreadyRedirected]);

  if (!loginUserMutation.isSuccess) {
    return <GridLoadingComponent />;
  }

  return null;
};

export default LoginWithTokenComponent;
