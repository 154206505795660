import { useMutation } from '@tanstack/react-query';
import { signIn } from 'next-auth/react';

import { CONFIG } from '@/lib/config/config';
import { processApiResponseReactQuery } from '@/lib/api/process-api-response-react-query.helper';
import { requestApi } from '@/lib/api/request-api.helper';
import { useGlobalStore } from '@/lib/state-management/useGlobalStore';
import { LoginRequestProps } from '@/lib/auth/auth.types';
import { pushAnalyticsEvent } from '@/lib/helpers/analytics/analytics.helper';
import { MixpanelWrapper } from '@/lib/mixpanel/useMixpanel';
import { useRouter } from 'next/router';
import { User } from '@/types/user/user';

// TODO (REFACTOR:LOGIN): just one mutation, duplicated code in user-login.mutation.ts
export const useLoginUserMutation = () => {
  const url = `${CONFIG.API.ENDPOINT}${CONFIG.API.METHODS.USER_SESSION}`;

  const router = useRouter();
  const { pathname, query, asPath } = router;

  return useMutation({
    mutationKey: ['user-session'],
    mutationFn: async ({ username, password, redirectAfterLogin = false }: LoginRequestProps) => {
      const response = await requestApi.post({
        url,
        body: { username, password }
      });

      // we cannot call the response.json() twice, then we are using the response.clone() that exists for this purpose to make that possible
      const newResponse = response.clone();
      useGlobalStore.getState().resetStore();
      MixpanelWrapper.reset();

      const newResponseJson = await newResponse.json();

      pushAnalyticsEvent({
        event: 'login',
        method: 'LOGIN_METHOD_HERE',
        user_data: {
          user_id: newResponseJson?.data?._id,
          email_address: newResponseJson?.data?.email
        }
      });

      if (newResponseJson && newResponseJson.data) {
        redirectAfterLogin
          ? await signIn('credentials', {
              redirect: redirectAfterLogin,
              callbackUrl: CONFIG.URL.SETTINGS_SHARING.path,
              user: JSON.stringify(newResponseJson?.data)
            })
          : await signIn('credentials', {
              redirect: false,
              user: JSON.stringify(newResponseJson?.data)
            });
      }

      return await processApiResponseReactQuery({ response });
    },
    onSuccess: (user: User) => {
      // Redirect to the user's language if it's different from the current locale
      if (user?.language && router.locale !== user.language) {
        router.push({ pathname, query }, asPath, { locale: user.language });
      }
    }
  });
};
