import { CircularProgress, Stack } from '@mui/material';

const GridLoadingComponent = (props) => (
  <Stack
    sx={{ width: '100%', p: 3 }}
    alignItems='center'
    justifyContent='flex-start'
    data-cy='grid-loading-component'
  >
    <CircularProgress {...props} disableShrink />
  </Stack>
);

export default GridLoadingComponent;
