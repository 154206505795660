import { useMaintenanceInfoStore } from '@/components/maintenance-info/useMaintenanceInfoStore';
import { useTranslation, Trans } from 'next-i18next';
import dayjs from 'dayjs';
import { unleashClient } from '@/lib/feature-flags/unleash-client';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import { useRouter } from 'next/router';
import { StatusInfo } from '../ui/status-info.component';
import dynamic from 'next/dynamic';
import EngineeringIcon from '@mui/icons-material/Engineering';
const MaintenanceInfoAlert = dynamic(
  () => import('./maintenance-info-alert.component').then((mod) => mod.MaintenanceInfoAlert),
  {
    ssr: false
  }
);
interface MaintenanceInfoProps {
  isMaintenance?: boolean;
}

export const MaintenanceInfo: React.FC<MaintenanceInfoProps> = ({ isMaintenance = false }) => {
  const isNotifactionHidden = useMaintenanceInfoStore((state) => state.isNotifactionHidden);

  const { t } = useTranslation();

  const { locale } = useRouter();
  const { payload } = unleashClient.getVariant(FEATURE_FLAGS.MAINTENANCE_INFO);
  const maintenanceDates = JSON.parse(payload?.value || '');
  const maintenanceStartDate = dayjs(maintenanceDates.start_date).utc().local();
  const maintenanceEndDate = dayjs(maintenanceDates.end_date).utc().local();
  const transValues = {
    date: maintenanceStartDate.format(locale === 'en' ? 'MM-DD-YYYY' : 'DD.MM.YYYY'),
    time: maintenanceStartDate.format(locale === 'en' ? 'h A' : 'HH:mm'),
    end_time: maintenanceEndDate.format(locale === 'en' ? 'h A' : 'HH:mm')
  };
  if (isMaintenance) {
    return (
      <StatusInfo
        title={t('maintenance.title')}
        message={<Trans i18nKey='maintenance.active' values={transValues} />}
        icon={<EngineeringIcon sx={{ fontSize: 64 }} color='primary' />}
      />
    );
  }

  if (!isMaintenance && !isNotifactionHidden) {
    return (
      <MaintenanceInfoAlert
        key='maintenance.announcement'
        message={<Trans i18nKey='maintenance.announcement' values={transValues} />}
      />
    );
  }

  return null;
};
