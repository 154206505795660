import { create } from 'zustand';

type MaintenanceInfoStoreProps = {
  isNotifactionHidden: boolean;
  setIsNotifactionHidden: (arg1: boolean) => void;
};

export const useMaintenanceInfoStore = create<MaintenanceInfoStoreProps>((set) => ({
  isNotifactionHidden: false,
  setIsNotifactionHidden: (isHidden: boolean) => {
    set({ isNotifactionHidden: isHidden });
  }
}));
