import DashboardLayoutComponent from '../dashboard/dashboard-layout-component/dashboard-layout.component';
import dayjs from 'dayjs';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import utc from 'dayjs/plugin/utc';
import { BaseLayout } from './layout.style';
import { CONFIG } from '@/lib/config/config';
import { FEATURE_FLAGS } from '@/lib/feature-flags/feature-flags.constants';
import { MaintenanceInfo } from '../maintenance-info/maintenance-info.component';
import { unleashClient } from '@/lib/feature-flags/unleash-client';
import { useFlag } from '@unleash/proxy-client-react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import type { ReactNode } from 'react';

dayjs.extend(utc);

const Analytics = dynamic(() => import('../analytics/analytics'), {
  ssr: false
});

const FooterComponent = dynamic(() => import('@/components/footer/footer.component'), {
  ssr: false
});

const NotificationsComponent = dynamic(
  () => import('@/components/notification/notification.component'),
  {
    ssr: false
  }
);

interface LayoutComponentProps {
  variant?: 'base' | 'dashboard';
  children: ReactNode;
}

const LayoutComponent = ({ children, variant = 'dashboard' }: LayoutComponentProps) => {
  const { t } = useTranslation(['common']);
  const title = t('common:pages.title');
  const description = t('common:pages.description');
  const { asPath } = useRouter();
  const index_paths = [CONFIG.URL.SIGNUP_ACTIVATE.path, CONFIG.URL.LOGIN.path];
  const indexing = index_paths.some((path) => asPath.startsWith(path))
    ? 'INDEX,FOLLOW'
    : 'NOINDEX,NOFOLLOW';
  const canonical = index_paths.find((path) => asPath.startsWith(path));
  const isFeatureFlagMaintenanceInfoEnabled = useFlag(FEATURE_FLAGS.MAINTENANCE_INFO);

  if (isFeatureFlagMaintenanceInfoEnabled) {
    const { payload } = unleashClient.getVariant(FEATURE_FLAGS.MAINTENANCE_INFO);
    const maintenanceDates = JSON.parse(payload?.value || '');
    const maintenanceStartDate = dayjs(maintenanceDates.start_date).utc().local();
    const isMaintenance = dayjs().isAfter(maintenanceStartDate);

    if (isMaintenance) {
      return (
        <BaseLayout>
          <MaintenanceInfo isMaintenance={isMaintenance} />
        </BaseLayout>
      );
    }
  }

  // TODO: check if dashboardlayout should be renderd when session is there -> {variant === 'dashboard' && session.status === 'authenticated' ?

  return (
    <>
      <Head>
        <meta name='robots' content={indexing} />
        {canonical && <link rel='canonical' href={`${CONFIG.APP.ROOT_URL}${canonical}`} />}
        <title>{title}</title>
        <meta name='description' content={description} />
        <meta property='og:description' content={description} />
      </Head>

      {isFeatureFlagMaintenanceInfoEnabled ? <MaintenanceInfo /> : null}
      {variant === 'base' ? (
        <>
          <BaseLayout>{children}</BaseLayout>
          <FooterComponent />
        </>
      ) : null}

      {variant === 'dashboard' ? (
        <DashboardLayoutComponent>{children}</DashboardLayoutComponent>
      ) : null}

      <NotificationsComponent />
      <Analytics />
    </>
  );
};

export default LayoutComponent;
