import { useState } from 'react';

import { Facebook, Google } from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  GlobalStyles,
  Grid,
  Stack,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Trans, useTranslation } from 'next-i18next';
import Link from 'next/link';

import { CONFIG } from '@/lib/config/config';

export const BaseLayout = styled(Box)(() => {
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    overflowY: 'scroll',
    backgroundImage: 'url(/assets/arthy_logo.svg)',
    backgroundSize: '140px',
    backgroundRepeat: 'no-repeat',
    backgroundPositionX: isSmallScreen ? 'center' : '32px',
    backgroundPositionY: '32px'
  };
});

export const FormStyle = styled(Box)(() => ({
  //@ts-ignore
}));

export const TextLink = ({
  href,
  title,
  color = 'text.secondary',
  dataCy
}: {
  href: string;
  title: string;
  color?: string;
  dataCy?: string;
}) => (
  <Link data-cy={dataCy} href={href}>
    <Typography
      color={color}
      variant='body2'
      display='flex'
      justifyContent='center'
      sx={{
        cursor: 'pointer',
        '&:hover': {
          textDecorationLine: 'underline'
        }
      }}
    >
      {title}
    </Typography>
  </Link>
);

export const Background = () => {
  const isSmallScreen = useMediaQuery('(max-width:900px)');

  return (
    <GlobalStyles
      styles={{
        body: {
          backgroundImage: 'url(/assets/arthy_logo.svg)',
          backgroundSize: '200px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'top',
          backgroundPositionY: isSmallScreen ? '32px' : '64px'
        }
      }}
    />
  );
};

export const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  },
  justifyContent: 'center'
}));

export const ALinkStyle = styled('a')(({ theme }) => ({
  '&:link, &:visited': {
    color: theme.palette.text.secondary,
    textDecoration: 'underline'
  }
}));

export const ContentStyle = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  flexDirection: 'column',
  justifyContent: 'top',
  width: 'fit-content',
  maxWidth: 560
}));

export const DividerStyled = styled(Divider)(() => ({
  marginTop: 34,
  marginBottom: 20
}));

export const TextLink2 = ({ href, title, color = 'text.secondary' }) => (
  <Link href={href} legacyBehavior>
    <Typography
      color={color}
      variant='body2'
      display='flex'
      justifyContent='center'
      sx={{
        cursor: 'pointer',
        '&:hover': {
          textDecorationLine: 'underline'
        }
      }}
    >
      {title}
    </Typography>
  </Link>
);

export const TermsAndConditions = ({ checked, handleChange }) => {
  const { t, i18n } = useTranslation('auth');

  const { language } = i18n;
  const languageUrls = {
    en: {
      terms: CONFIG.URL.TERMS.path.en,
      privacy: CONFIG.URL.PRIVACY.path.en,
      imprint: CONFIG.URL.IMPRINT.path.en
    },
    de: {
      terms: CONFIG.URL.TERMS.path.de,
      privacy: CONFIG.URL.PRIVACY.path.de,
      imprint: CONFIG.URL.IMPRINT.path.de
    }
  };

  const getUrlForLanguage = (key) => languageUrls[language][key];

  return (
    <FormControlLabel
      sx={{
        pl: 2,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      }}
      control={
        <Checkbox
          sx={{
            minWidth: 'fit-content'
          }}
          checked={checked}
          onChange={handleChange}
          data-cy='sign-up-terms-checkbox'
        />
      }
      label={
        <Box pt={1}>
          <Typography variant='body2' color='text.secondary'>
            {/* @ts-ignore} */}
            <Trans i18nKey='auth:sign-up.terms'>
              <ALinkStyle href={getUrlForLanguage('terms')} target='_blank'>
                {{
                  // @ts-ignore
                  linkTextTerms: t('sign-up.linkTextTerms')
                }}
              </ALinkStyle>
              <ALinkStyle href={getUrlForLanguage('privacy')} target='_blank'>
                {{
                  // @ts-ignore
                  linkTextPrivacy: t('sign-up.linkTextPrivacy')
                }}
              </ALinkStyle>
            </Trans>
          </Typography>
        </Box>
      }
    />
  );
};

export const ProgrammTerms = ({ checked, handleChange }) => {
  const { t } = useTranslation('auth');

  return (
    <FormControlLabel
      sx={{
        pl: 2,
        width: '100%',
        alignItems: 'flex-start',
        justifyContent: 'flex-start'
      }}
      control={
        <Checkbox
          sx={{
            minWidth: 'fit-content'
          }}
          checked={checked}
          onChange={handleChange}
        />
      }
      label={
        <Box pt={1.2} sx={{ justifyContent: 'center' }}>
          <Typography variant='body2' color='text.secondary' sx={{ textAlignLast: 'center' }}>
            {t('sign-up.programmTerms', { ns: 'auth' })}
          </Typography>
        </Box>
      }
    />
  );
};

export const FBGLogin = ({ fCaption, gCaption, withTerms }) => {
  const [cbChecked, setCbChecked] = useState(false);

  const handleCbChange = () => {
    setCbChecked((oldValue) => !oldValue);
  };

  return (
    <Grid container mt={4} spacing={2}>
      <Grid item xs={6}>
        <Link href={CONFIG.API.ENDPOINT + CONFIG.API.OAUTH.FB.SIGN_UP} legacyBehavior>
          <Button
            variant='outlined'
            fullWidth
            disabled={!cbChecked && withTerms}
            startIcon={<Facebook />}
          >
            {fCaption}
          </Button>
        </Link>
      </Grid>
      <Grid item xs={6}>
        <Link href={CONFIG.API.ENDPOINT + CONFIG.API.OAUTH.G.SIGN_UP} legacyBehavior>
          <Button
            variant='outlined'
            fullWidth
            disabled={!cbChecked && withTerms}
            startIcon={<Google />}
          >
            {gCaption}
          </Button>
        </Link>
      </Grid>
      {withTerms && (
        <Grid item xs={12}>
          <TermsAndConditions checked={cbChecked} handleChange={handleCbChange} />
        </Grid>
      )}
    </Grid>
  );
};
